import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

import Carousel from '@components/Carousel/Carousel'
import VideoModal from '@components/VideoModal'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import { stripHtml } from '@modules/common/utils'
import { PersonalizedPostsTeaserProps } from '@lib/datocms/types/personalizedContent'

const PostsTeaser = ({ id, headline, posts }: PersonalizedPostsTeaserProps) => {
  const items: JSX.Element[] = posts
    .filter((post) => post)
    .map((post) => {
      const image = post.mainImageVideo.responsiveImage
      const video = post.mainImageVideo
      return (
        <div key={post.id} className="flex flex-col mt-4">
          {video.video && <VideoModal video={video} posterFormat="4:3" />}
          <Link href={`/posts/${post.slug}`} className="group">
            {image && (
              <div className="overflow-hidden">
                <Image
                  alt={headline}
                  src={image.src}
                  width={image.width}
                  height={image.height}
                  className="group-hover:scale-105 transform transition-all"
                  sizes="(min-width: 767px) 33vw, 100vw"
                />
              </div>
            )}
            <h4 className="text-xs mt-8 mb-2 uppercase">{post.category.name}</h4>
            <h3 className="text-base font-bold mb-2">{post.headline}</h3>
            <div
              className="text-base mb-2 line-clamp--5"
              dangerouslySetInnerHTML={{
                __html: stripHtml(post.summary)
              }}
            />
            <div className="btn btn--blank">
              <ArrowToRightIcon />
              <span>MEHR</span>
            </div>
          </Link>
        </div>
      )
    })

  return (
    <section id={id} className="bg-white section-content-width section-spacing relative">
      <h2 className="headline-section mb-6 md:ml-1 block">{headline}</h2>
      <Carousel
        id={id}
        carouselItems={items}
        slidesToShowMobile={1}
        slidesToShowDesktop={3}
        width="trimmed"
      />
    </section>
  )
}

export default PostsTeaser
