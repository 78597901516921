import React from 'react'
import StarOutline from '@components/icons/StarOutline'
import Star from '@components/icons/Star'
import classNames from 'classnames'

type StarsProps = {
  score: number
  numberOfReviews?: number
  size?: 'normal' | 'small'
}

const Stars = ({ score, numberOfReviews = null, size = 'normal' }: StarsProps) => {
  const roundedScore = Math.floor(score * 2) / 2

  return (
    <div className="flex">
      <div className="relative flex items-center">
        <div className="flex">
          <div className={classNames({ 'w-5': size === 'normal', 'w-4': size === 'small' })}>
            <StarOutline />
          </div>
          <div className={classNames({ 'w-5': size === 'normal', 'w-4': size === 'small' })}>
            <StarOutline />
          </div>
          <div className={classNames({ 'w-5': size === 'normal', 'w-4': size === 'small' })}>
            <StarOutline />
          </div>
          <div className={classNames({ 'w-5': size === 'normal', 'w-4': size === 'small' })}>
            <StarOutline />
          </div>
          <div className={classNames({ 'w-5': size === 'normal', 'w-4': size === 'small' })}>
            <StarOutline />
          </div>
        </div>
        <div
          data-testid="stars"
          style={{ width: `${Math.round((roundedScore * 100) / 5)}%` }}
          className={classNames('absolute top-1/2 -translate-y-1/2 overflow-hidden', {
            'h-5': size === 'normal',
            'h-4': size === 'small'
          })}
          title={(Math.round(score * 100) / 100).toString()}>
          <div
            className={classNames({
              'w-[100px]': size === 'normal',
              'w-[80px]': size === 'small'
            })}>
            <div
              className={classNames('float-left', {
                'w-5': size === 'normal',
                'w-4': size === 'small'
              })}>
              <Star />
            </div>
            <div
              className={classNames('float-left', {
                'w-5': size === 'normal',
                'w-4': size === 'small'
              })}>
              <Star />
            </div>
            <div
              className={classNames('float-left', {
                'w-5': size === 'normal',
                'w-4': size === 'small'
              })}>
              <Star />
            </div>
            <div
              className={classNames('float-left', {
                'w-5': size === 'normal',
                'w-4': size === 'small'
              })}>
              <Star />
            </div>
            <div
              className={classNames('float-left', {
                'w-5': size === 'normal',
                'w-4': size === 'small'
              })}>
              <Star />
            </div>
          </div>
        </div>
      </div>
      {numberOfReviews && (
        <div
          className={classNames('text-sm', {
            'ml-3': size === 'normal',
            'ml-2': size === 'small'
          })}>
          {numberOfReviews}
        </div>
      )}
    </div>
  )
}

export default Stars
