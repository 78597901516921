import Image from 'next/image'
import React from 'react'

import VideoPlayer from '@components/VideoPlayer'
import { YoImageOrVideo } from '@modules/common/types'

type ExpertProps = {
  image: YoImageOrVideo
  text: string
}

const ExpertInExpertRecommendation = ({ image, text }: ExpertProps) => (
  <div className="w-full flex md:w-1/2 md:px-3 mb-8">
    <div className="flex flex-wrap w-full">
      <div className="w-full md:w-1/2 md:pr-3 md:bg-gray-200 flex">
        {image.video && <VideoPlayer video={image} />}
        {image.responsiveImage && (
          <div className="relative w-full h-[70vw] md:h-full">
            <Image
              alt={image.alt || 'Expert Recommendation'}
              fill
              src={image.responsiveImage}
              style={{ objectPosition: image.focalPoint }}
              className="rounded-tl-lg object-cover"
              sizes="(min-width: 767px) 25vw, 100vw)"
            />
          </div>
        )}
      </div>
      <div className="flex flex-wrap py-4 w-full md:w-1/2 px-4 items-center bg-gray-200 rounded-br-lg mb-8 md:mb-0">
        <div className="flex flex-wrap w-full justify-center">
          <div className="-mt-10 w-12 h-12 bg-gray-200 rounded-full z-10 p-1 md:mt-0">
            <Image alt="yoself Icon" width="50" height="50" src="/images/yoself_icon_black.png" />
          </div>
          <p className="w-full text-black text-sm whitespace-pre-wrap font-bold md:text-base md:mt-8 pb-4 md:pb-2 lg:px-6">
            {text}
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default ExpertInExpertRecommendation
