import { IProductSnippet } from '@modules/products/types'
import trackEvent from './trackEvent'

const trackViewItemListEvent = (id, headline, products: IProductSnippet[]): void => {
  trackEvent({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: id,
      item_list_name: headline || 'Product List',
      items: products
        .filter((product) => product)
        .map((product: IProductSnippet) => ({
          item_brand: product.brand.name,
          item_id: product.sku,
          item_name: product.name,
          price: product.priceRange.minVariantPrice.amount,
          quantity: 1
        }))
    }
  })
  trackEvent({
    event: 'ua_view_item_list',
    ecommerce: {
      currencyCode: 'EUR',
      impressions: {
        products: products
          .filter((product) => product)
          .map((product: IProductSnippet) => ({
            brand: product.brand.name,
            category: '',
            coupon: '',
            id: product.sku,
            name: product.name,
            price: product.priceRange.minVariantPrice.amount,
            quantity: 1,
            variant: ''
          }))
      }
    }
  })
}

export default trackViewItemListEvent
