import { IProduct, IProductVariant } from '@modules/products/types'
import { shopifyToEuro } from '../utils'

export interface IPrices {
  fromPrice: string | null
  price: string
  regularPrice: string | null
}

const formatPrice = (price: string): string => shopifyToEuro(parseFloat(price))

export function getFromPrice(product: IProduct): string | null {
  const variants: IProductVariant[] = Object.values(product.variantsBySKU)
  if (variants.length <= 1) {
    return null
  }

  const variantPrices: number[] = variants.map((variant: IProductVariant) =>
    parseFloat(variant.price)
  )

  const allPricesEqual = (array) => array.every((v) => v === array[0])
  if (allPricesEqual(variantPrices)) {
    return null
  }

  const lowestPrice = Math.min(...variantPrices)

  return `ab ${formatPrice(lowestPrice.toString())}`
}

function getPrice(product: IProduct, variantSKU: string): string | null {
  const variant = product.variantsBySKU[variantSKU]

  const price = variant ? variant.price : product.price

  return formatPrice(price)
}

export function getRegularPrice(product: IProduct, variantSKU: string): string | null {
  const variant = product.variantsBySKU[variantSKU]
  const price = variant ? variant.price : product.price
  const regularPrice = variant ? variant.regularPrice : product.regularPrice

  return regularPrice && parseFloat(regularPrice) > parseFloat(price)
    ? formatPrice(regularPrice)
    : null
}

const getVariantPrices = ({ product, variantSKU }): IPrices => {
  const fromPrice = getFromPrice(product)
  const price = getPrice(product, variantSKU)
  const regularPrice = getRegularPrice(product, variantSKU)

  return {
    fromPrice,
    price,
    regularPrice
  }
}

export const bundleDiscount = (price: string, regularPrice: string): number =>
  Math.floor(100 - (parseFloat(price) / parseFloat(regularPrice)) * 100)

export default getVariantPrices
