import React from 'react'

import Link from 'next/link'
import Image from 'next/image'
import { IProductSnippet } from '@modules/products/types'
import { Price } from '@components/Price'
import { getImagesAndLinksForProductLists } from '@modules/common/utils'
import Stars from '@components/Stars'

type ProductComponentProps = {
  product: IProductSnippet
  imageSizes?: string
  imageType?: string
}

const Product = ({ product, imageSizes = '100vw', imageType = 'mood' }: ProductComponentProps) => {
  const showBundleBatch = product.isBundle
  const showDealBatch =
    parseFloat(product.compareAtPriceRange.minVariantPrice.amount) >
    parseFloat(product.priceRange.minVariantPrice.amount)

  const { imageToShow, linkWithHash } = getImagesAndLinksForProductLists(imageType, product)

  if (!imageToShow || !imageToShow.responsiveImage) return null
  return (
    <Link legacyBehavior href={linkWithHash}>
      <a href={linkWithHash}>
        <div className="group w-full relative">
          <div className="overflow-hidden aspect-w-4 aspect-h-3 bg-gray-200 group-hover:bg-gray-300">
            <Image
              className="md:group-hover:scale-105 transition-all object-cover"
              src={imageToShow.responsiveImage.src}
              alt={imageToShow.alt}
              quality={60}
              fill
              sizes={imageSizes}
              style={{
                objectPosition: `${imageToShow.focalPoint.x * 100}% ${
                  imageToShow.focalPoint.y * 100
                }%`
              }}
            />
          </div>
          {showDealBatch && !showBundleBatch && (
            <div className="absolute top-0 left-0 m-2 md:m-4 py-1 px-2 uppercase tracking-wider bg-black text-white text-xs font-bold z-1">
              Deal
            </div>
          )}
          {showBundleBatch && (
            <div className="absolute top-0 left-0 m-2 md:m-4 py-1 px-2 uppercase tracking-wider bg-black text-white text-xs font-bold z-1">
              Bundle
            </div>
          )}
          <div className="my-4">
            <span className="uppercase text-xs md:text-sm tracking-wide">{product.brand.name}</span>
            <h4 className="font-bold text-base w-full break-words group-hover:underline md:text-lg">
              {product.name}
            </h4>
            {product.reviews.average && product.reviews.count && (
              <div className="my-0.5">
                <Stars
                  score={product.reviews.average}
                  numberOfReviews={product.reviews.count}
                  size="small"
                />
              </div>
            )}
            <Price product={product} />
          </div>
        </div>
      </a>
    </Link>
  )
}

export default Product
