import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

import { IDatoCMSPodcastTeaser } from '@lib/datocms/types/contentSections'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import { getLinkFromCallToAction } from '@modules/common/utils'
import PodcastIcon from '@components/icons/PodcastIcon'

interface IPodcastTeaser {
  podcastTeaser: IDatoCMSPodcastTeaser
  id: string
}

const PodcastTeaser: React.FC<IPodcastTeaser> = ({ podcastTeaser, id }) => {
  const image = podcastTeaser.image.responsiveImage
  const cta = podcastTeaser.callToAction[0]

  return (
    <section
      id={id}
      className="bg-white section-content-width section-spacing flex flex-col md:flex-row">
      <div className="-mx-4 md:w-2/5 md:mx-0 relative aspect-1">
        <Image
          alt={podcastTeaser.headline}
          fill
          src={image.src}
          className="object-cover"
          sizes="(min-width: 767px) 66vw, 100vw"
        />
      </div>
      <div className="relative md:w-3/5 bg-gray-200 -mx-4 px-4 md:mx-0 md:px-0">
        <div className="w-28 absolute right-4 -top-14 md:right-8 md:top-8">
          <PodcastIcon />
        </div>
        <div className="flex flex-col justify-center h-full section-spacing md:py-8 md:px-16">
          <h3 className="overline-section mb-4">{podcastTeaser.overline}</h3>
          <h2 className="headline-section mb-8 md:mb-12 md:mr-28">{podcastTeaser.headline}</h2>
          <p className="mb-8">{podcastTeaser.text}</p>
          <iframe
            title={podcastTeaser.headline}
            src={podcastTeaser.spotifyUrl}
            width="100%"
            height="152"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            allowFullScreen
            className="border-0"
            loading="lazy"
          />
          {cta && (
            <Link legacyBehavior href={getLinkFromCallToAction(cta)}>
              <a className="btn btn--borderless mt-4">
                <ArrowToRightIcon />
                <span>{cta.buttonText}</span>
              </a>
            </Link>
          )}
        </div>
      </div>
    </section>
  )
}

export default PodcastTeaser
