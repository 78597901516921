function PricingComparisonTable({ id }) {
  const rows = [
    ['Ausführliches Anamnesegespräch', '120 €', 'inklusive'],
    ['4x45 min 1:1 Coaching Session', '600 €', 'inklusive'],
    ['12 Wochen Unterstützung von einem Health Coach', '680 €', 'inklusive'],
    ['Wöchentliche neue Inhalte und Übungen', '350 €', 'inklusive'],
    ['Live Q&A mit Schlafexperten', '100 €', 'inklusive'],
    ['Sleep Analyzer', '149 €', 'inklusive'],
    ['Endlich wieder besser schlafen', `Unbezahlbar`, 'inklusive'],
    ['Komplettpreis für 12 Wochen', '1.999 €', '999 €']
  ]
  return (
    <section id={id} className="section-content-width section-spacing">
      <div className="lg:text-center">
        <div className="mt-20">
          <div className="uppercase">Jetzt Preisvorteil in unserem Komplettpaket sichern</div>
          <h2 className="headline-section text-4xl">Unser Mehrwert für deine Gesundheit</h2>
          <div className="flex justify-center mt-8">
            <div className="w-full max-w-4xl">
              <table className="table-auto font-bold">
                <thead>
                  <tr className="uppercase text-sm headline-section lg:text-xl">
                    <th className="py-2 lg:p-4 border-4 border-white bg-accent-200 text-black ">
                      Programm Inhalte
                    </th>
                    <th className="py-2 lg:p-4 border-4 border-white bg-gray-200 text-black">
                      Preis
                    </th>
                    <th className="py-2 lg:p-4 border-4 border-white bg-accent-300 text-black">
                      yoself Sleep Coaching
                    </th>
                  </tr>
                </thead>
                <tbody className="text-xs text-center lg:text-base">
                  {rows.map((row, index) => (
                    <tr className={index === rows.length - 1 ? 'headline-section text-lg' : ''}>
                      <td className="py-2 lg:p-4 border-4 border-white bg-accent-200">{row[0]}</td>
                      <td className="py-2 lg:p-4 border-4 border-white bg-gray-200 uppercase line-through">
                        {row[1]}
                      </td>
                      <td className="py-2 lg:p-4 border-4 border-white bg-accent-300 uppercase">
                        {row[2]}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="uppercase text-lg headline-section lg:text-2xl">
                    <th className="py-2 lg:p-4 border-4 border-white bg-accent-400 text-black ">
                      pro Woche
                    </th>
                    <th className="py-2 lg:p-4 border-4 border-white bg-gray-400 text-black line-through">
                      166,58 €
                    </th>
                    <th className="py-2 lg:p-4 border-4 border-white bg-accent-500 text-white whitespace-nowrap">
                      83,20 €
                    </th>
                  </tr>
                </tfoot>
              </table>
              <div className="text-right mt-4 mr-1 lg:mr-14">
                <div className="inline-block bg-black w-40 text-white text-center p-2 rounded">
                  <div className="text-s">Nur für kurze Zeit</div>
                  <div className="text-2xl font-bold">41,60€</div>
                  <div className="text-s">pro Woche</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PricingComparisonTable
