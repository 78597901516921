import React from 'react'

import { IProductSnippet } from '@modules/products/types'
import getPrices, { bundleDiscount } from '@utils/price'
import classNames from 'classnames'

type PriceProps = {
  product: IProductSnippet
  showBundleText?: boolean
}

const Price = ({ product, showBundleText }: PriceProps) => {
  const { price, regularPrice, fromPrice } = getPrices({ product })

  const bundleDiscountAmount = bundleDiscount(
    product.priceRange.minVariantPrice.amount,
    product.compareAtPriceRange.minVariantPrice.amount
  )

  const hasDiscount =
    bundleDiscountAmount !== 0 &&
    parseFloat(product.compareAtPriceRange.minVariantPrice.amount) >
      parseFloat(product.priceRange.minVariantPrice.amount)

  if (!product) return null
  return (
    <div className="text-sm md:text-base">
      <span
        data-testid="price"
        className={classNames('mr-4', {
          'text-red-700': regularPrice
        })}>
        {fromPrice || price}
      </span>
      {regularPrice && (
        <span data-testid="regular-price" className="text-gray-400 line-through mr-4">
          {regularPrice}
        </span>
      )}
      {showBundleText && product.isBundle && hasDiscount && (
        <span data-testid="bundleDiscount" className="font-bold text-red-700">
          {`Du sparst ${bundleDiscountAmount.toString()}% `}
        </span>
      )}
    </div>
  )
}

export default Price
