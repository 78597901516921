import { IDatoCMSSelfHostedVideo } from '@lib/datocms/types/misc'
import trackEvent from './trackEvent'

export const trackVideoPlayerEventStart = (video: IDatoCMSSelfHostedVideo): void => {
  trackEvent({
    event: 'video_player_start',
    videoDetails: video.title
  })
}

export const trackVideoPlayerEventEnd = (video: IDatoCMSSelfHostedVideo): void => {
  trackEvent({
    event: 'video_player_end',
    videoDetails: video.title
  })
}
