import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'

import UiContext from '@context/UiContext'
import MiniNavigation from './MiniNavigation'

type CarouselProps = {
  id: string
  carouselItems: JSX.Element[]
  slidesToShowMobile: 1 | 2
  slidesToShowDesktop: 1 | 2 | 3 | 4
  gap?: 0 | 3 | 4
  width: 'full' | 'trimmed' | 'fullOnDesktop'
  showAllOnDesktop?: boolean
  noSlideToBeSaved?: boolean
  croppedImagePosition?: number
}
const Carousel = ({
  id,
  carouselItems,
  slidesToShowMobile,
  slidesToShowDesktop,
  gap = 4,
  width,
  showAllOnDesktop = false,
  croppedImagePosition,
  noSlideToBeSaved
}: CarouselProps) => {
  const [activeCarouselItemIndex, setActiveCarouselItemIndex] = useState(0)
  const scrollAreaRef = useRef<HTMLDivElement>()
  const carouselItemRefs = useRef([])
  carouselItemRefs.current = carouselItems.map((_, i) => carouselItemRefs.current[i] ?? createRef())
  const { carouselPositions, setCarouselPositions } = useContext(UiContext)

  const scrollToLeft = (left: number, behavior) => {
    scrollAreaRef.current.scrollTo({
      left,
      behavior
    })
  }

  const scrollToElementByIndex = (index, behavior) => {
    const left = carouselItemRefs.current[index].current.offsetLeft

    scrollToLeft(left, behavior)
  }

  const checkIfElementIsInViewOfContainer = (container, element): boolean => {
    const isInViewOffset = element.offsetWidth * 0.05
    if (container && element) {
      // Get container properties
      const cLeft = container.scrollLeft
      const cRight = cLeft + container.offsetWidth

      // Get element properties
      const eLeft = element.offsetLeft
      const eRight = eLeft + element.offsetWidth - isInViewOffset

      // Check if in view
      return eLeft >= cLeft && eRight <= cRight
    }
    return false
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollElement = event.currentTarget
    carouselItems.forEach((carouselItem, index) => {
      const elm = carouselItemRefs.current[index].current
      if (checkIfElementIsInViewOfContainer(scrollElement, elm)) {
        setCarouselPositions({ ...carouselPositions, [id]: elm.offsetLeft })
        setActiveCarouselItemIndex(index)
      }
    })
  }

  const switchCarouselItem = (direction: 'left' | 'right') => {
    const scrollAreaScrollLeft = scrollAreaRef.current.scrollLeft
    const itemWidth = carouselItemRefs.current[0].current.offsetWidth - 1 // magicNumber: otherwise the last slide cannot be reached
    const scrollAreaWidth =
      carouselItemRefs.current[carouselItemRefs.current.length - 1].current.offsetLeft + itemWidth
    let left: number
    if (direction === 'left') {
      left = scrollAreaScrollLeft - itemWidth
      if (left < 0) left = scrollAreaWidth
    } else {
      left = scrollAreaScrollLeft + itemWidth
      if (left + itemWidth * slidesToShowDesktop > scrollAreaWidth) left = 0
    }
    scrollToLeft(left, 'smooth')
  }

  useEffect(() => {
    if (croppedImagePosition) {
      scrollToElementByIndex(Number(croppedImagePosition) - 1, 'smooth')
    }
    if (noSlideToBeSaved) {
      return
    }
    if (carouselPositions[id]) {
      scrollAreaRef.current.scrollLeft = carouselPositions[id]
    }
  }, [])

  const mobileSlideWidth = (): string => {
    if (width !== 'full')
      return slidesToShowMobile === 1 ? 'w-[calc(100%_-_2rem)]' : 'w-[calc(50%_-_1rem)]'
    return 'w-full'
  }

  const desktopSlideWidth = width === 'full' || width === 'fullOnDesktop' ? 'md:w-full' : ''
  const isHiddenOnDesktop = slidesToShowDesktop >= carouselItems.length || showAllOnDesktop
  const isHiddenOnMobile = slidesToShowMobile >= carouselItems.length

  return (
    <div className={`-mx-${gap} relative`}>
      <div className="w-full">
        <div
          className={`no-scrollbar w-full flex snap-x snap-mandatory overflow-hidden overflow-x-scroll ${
            showAllOnDesktop ? 'md:flex-wrap' : ''
          }`}
          ref={scrollAreaRef}
          id={id}
          onScroll={handleScroll}>
          {carouselItems.map((carouselItem, index) => {
            const key = `carouselItem-${index + 1}`

            return (
              <div
                key={key}
                ref={carouselItemRefs.current[index]}
                className={`relative snap-always snap-start flex-shrink-0 pl-${gap} ${mobileSlideWidth()} md:w-1/${slidesToShowDesktop} md:p-${gap} ${desktopSlideWidth}`}>
                {carouselItem}
              </div>
            )
          })}
          {/* the only way to get padding to the right of the last element (safari mobile) */}
          <div className={`pr-${gap} md:pr-0`} />
        </div>
        <div
          className={classnames('m-auto text-center flex justify-center items-center mt-2', {
            'md:hidden': slidesToShowDesktop > 1,
            hidden: slidesToShowMobile > 1
          })}>
          {carouselItems.map((item, index) => {
            const key = `navItem-${index + 1}`

            return (
              // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
              <button
                key={key}
                type="button"
                aria-label={`Slide ${index + 1}`}
                className={classnames('slider-nav-item', {
                  'slider-nav-item__active': index === activeCarouselItemIndex,
                  'md:hidden': isHiddenOnDesktop,
                  'm-2': carouselItems.length <= 10,
                  'mx-1 my-2 md:m-2': carouselItems.length > 10,
                  hidden: isHiddenOnMobile
                })}
                onClick={() => scrollToElementByIndex(index, 'smooth')}
              />
            )
          })}
        </div>
      </div>
      <MiniNavigation
        switchCarouselItem={switchCarouselItem}
        isHiddenOnDesktop={isHiddenOnDesktop}
      />
      {/* tailwind does not know the styles otherwise */}
      <div className="pl-3 pr-3 -mx-3 md:p-3 hidden" />
    </div>
  )
}

export default Carousel
