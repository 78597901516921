import {
  ReceiptRefundIcon,
  CheckCircleIcon,
  LockOpenIcon,
  UserCircleIcon
} from '@heroicons/react/24/solid'
import { IDatoCMSPricingTable } from '@lib/datocms/types'
import LinkOrAnchorLink from '@components/LinkOrAnchorLink'

// I need a const with the current date + 7 days
const date = new Date()
const sevenDays = date.setDate(date.getDate() + 7)
// I need seven Days to be formatted as follows 05.08.2021
const formattedSevenDays = new Intl.DateTimeFormat('de-DE').format(sevenDays)

type StandardCoachingPricingProps = {
  pricingTable: IDatoCMSPricingTable
}

const StandardCoachingPricing = ({ pricingTable }: StandardCoachingPricingProps) => (
  <section id={pricingTable.id} className="section-content-width section-spacing">
    <div className="lg:text-center">
      <div className="flex flex-col lg:flex-row items-center ">
        <div className="">
          <div className="text-left w-full lg:w-10/12">
            <h3 className="text-lg font-bold uppercase mb-1">
              BIS {formattedSevenDays} 50% sparen
            </h3>
            <h1 className="text-3xl headline-section mb-8 leading-tight">Limitiertes Angebot</h1>
            <div className="flex mb-5">
              <UserCircleIcon className="w-12 h-12 mr-5 flex-shrink-0" />
              <div>
                <h4 className="headline-section text-xl">Persönlich zugeschnitten</h4>
                <p>
                  Wir schneiden die Inhalte und das Coaching individuell auf deine Bedürfnisse zu.
                  Damit du wieder besser schläfst und mehr Energie für deine Aufgaben hast.
                </p>
              </div>
            </div>
            <div className="flex mb-5">
              <ReceiptRefundIcon className="w-12 h-12 mr-5 flex-shrink-0" />
              <div>
                <h4 className="headline-section text-xl">Geld-zurück-Garantie</h4>
                <p>
                  Wir stehen dafür, dass du wieder besser schläfst. Ist das nicht der Fall erhälst
                  du dein Geld zurück. Ohne Wenn und Aber!
                </p>
              </div>
            </div>
            <div className="mb-10 flex">
              <LockOpenIcon className="w-12 h-12 mr-5 flex-shrink-0" />
              <div>
                <h4 className="headline-section text-xl">Kein Abonnement</h4>
                <p>
                  Unser Schlaf-Coaching endet automatisch nach 12 Wochen. Du kannst anschließend
                  weiter auf die Inhalte zugreifen.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-2 border-black pb-5 rounded-md text-center w-full lg:w-6/12">
          <div className="bg-accent-300 px-4 py-2 ">
            <span className="text-black font-bold text-xl ">12 WOCHEN COACHING</span>
          </div>
          <div>
            <div className="px-5 my-3">
              <div className="flex items-center justify-center">
                <s className="text-2xl font-bold text-accent-500 mr-6">83,20 €</s>
                <div className="bubble py-1 px-3 bg-black text-sm rounded shadow-md text-white">
                  <p className="">bis {formattedSevenDays} 50% Rabatt</p>
                </div>
              </div>
              <div className="mb-8">
                <div className="text-6xl font-extrabold">41,60 €</div>
                <div className="-mt-4 text-xl text-gray-400">pro Woche</div>
                <div className="font-bold text-sm">Gesamtpreis 499€ anstatt 999€ (12 Wochen)</div>
              </div>

              <div className="ml-3 text-left text-sm lg:text-lg">
                <div className="flex items-center mb-3">
                  <CheckCircleIcon className="w-5 h-5 mr-4 " />
                  <span>Ausführliches Erstgespräch zur Anamnese</span>
                </div>
                <div className="flex items-center mb-3">
                  <CheckCircleIcon className="w-5 h-5 mr-4" />
                  <span>Zugang zu einem Netz von Fachexperten</span>
                </div>
                <div className="flex items-center mb-3">
                  <CheckCircleIcon className="w-5 h-5 mr-4" />
                  <span>4x 1:1 Sessions mit Deinem Health Coach</span>
                </div>
                <div className="flex items-center mb-3">
                  <CheckCircleIcon className="w-5 h-5 mr-4" />
                  <span>12-wöchiges individuelles Programm</span>
                </div>
                <div className="flex items-center mb-3">
                  <CheckCircleIcon className="w-5 h-5 mr-4" />
                  <span>Kontinuierliche Unterstützung per Chat</span>
                </div>
                <div className="flex items-center mb-3">
                  <CheckCircleIcon className="w-5 h-5 mr-4" />
                  <span>Wöchentlich neue Inhalte und Übungen</span>
                </div>
                <div className="flex items-center mb-3">
                  <CheckCircleIcon className="w-5 h-5 mr-4" />
                  <span>Kostenloser Sleep-Analyzer</span>
                </div>
                <div className="flex items-center mb-3">
                  <CheckCircleIcon className="w-5 h-5 mr-4" />
                  <span>Tracking deiner Verhaltensänderung</span>
                </div>
                <div className="flex items-center mb-3">
                  <CheckCircleIcon className="w-5 h-5 mr-4" />
                  <span>Monatliche live Q&A mit Schlaf-Coaches</span>
                </div>
                <div className="flex items-center mb-3">
                  <CheckCircleIcon className="w-5 h-5 mr-4" />
                  <span>Exklusive Rabatte auf yoself-Produkte</span>
                </div>
              </div>
              {pricingTable.callToAction[0] && (
                <div className="my-6">
                  <LinkOrAnchorLink regularBlack callToAction={pricingTable.callToAction[0]} />
                </div>
              )}
            </div>
          </div>
          {pricingTable.bottomText && (
            <div className="font-bold text-sm">{pricingTable.bottomText}</div>
          )}
        </div>
      </div>
    </div>
  </section>
)

export default StandardCoachingPricing
