import React from 'react'
import Image from 'next/image'
import Carousel from '@components/Carousel'
import { getImageFormat, getNumberOfDesktopSlides } from '@modules/common/utils'
import VideoPlayer from '@components/VideoPlayer'
import { YoImageOrVideo } from '@modules/common/types'

type InstagramStoriesGalleryProps = {
  headline: string
  images: YoImageOrVideo[]
  id: string
}

const InstagramStoriesGallery = ({ images, headline, id }: InstagramStoriesGalleryProps) => {
  const format = getImageFormat(images[0])

  const carouselImageItems = images.map((image) => {
    const isVideo = image.video
    return (
      <div key={image.alt} className="w-full relative aspect-w-9 aspect-h-16">
        {!isVideo && (
          <Image
            src={image.responsiveImage.src}
            fill
            alt={image.alt}
            className="object-cover"
            style={{ objectPosition: image.focalPoint }}
            sizes="(min-width: 767px) 50vw, 100vw"
          />
        )}
        {isVideo && (
          <div className="">
            <VideoPlayer video={image} />
          </div>
        )}
      </div>
    )
  })

  return (
    <section id={id} className="bg-white section-content-width section-spacing">
      <h2 className="overline-section mb-4">{headline}</h2>

      <div className="my-8 md:my-10">
        <Carousel
          id={id}
          carouselItems={carouselImageItems}
          slidesToShowMobile={1}
          slidesToShowDesktop={getNumberOfDesktopSlides(format)}
          width="trimmed"
        />
      </div>
    </section>
  )
}

export default InstagramStoriesGallery
