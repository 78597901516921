import React from 'react'
import Image from 'next/image'

import VideoModal from '@components/VideoModal'
import VideoPlayer from '@components/VideoPlayer'
import { getLinkFromCallToAction, isVideoLandscape, isVideoPortrait } from '@modules/common/utils'
import { IBrandIntro } from '@modules/contentSections/types'
import LinkOrAnchorLink from '@components/LinkOrAnchorLink'
import Link from 'next/link'

const BrandIntro = ({
  id,
  overline,
  headline,
  text,
  image,
  callToAction,
  imagePosition
}: IBrandIntro) => {
  const reverse = imagePosition === 'right'
  const ctaLinkTarget = getLinkFromCallToAction(callToAction)

  return (
    <section id={id} className="bg-white section-content-width section-spacing" key={id}>
      <div
        className={`flex flex-col item-start md:items-center md:flex-row ${
          reverse ? 'md:flex-row-reverse md:justify-end' : ''
        }`}>
        <div className={`w-full md:w-1/2 mb-8 md:mb-0 ${reverse ? 'md:pl-3' : 'md:pr-3'}`}>
          {image.responsiveImage && (
            <Link href={ctaLinkTarget || '#'}>
              <Image
                alt={image.alt}
                sizes="(min-width: 767px) 50vw, 100vw"
                width={image.responsiveImage.width}
                height={image.responsiveImage.height}
                src={image.responsiveImage.src}
              />
            </Link>
          )}
          {isVideoPortrait(image) && <VideoModal video={image} />}
          {isVideoLandscape(image) && <VideoPlayer video={image} />}
        </div>
        <div className={`w-full md:w-1/2 ${reverse ? 'md:pl-0 md:pr-12' : 'md:pl-12 md:pr-3'}`}>
          <h2 className="overline-section mb-2 md:mb-4">{overline}</h2>
          <h3 className="headline-section mb-8 md:mb-6">{headline}</h3>
          <div
            className="text-content-section text-sections-list pb-4 max-w-xl"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <LinkOrAnchorLink callToAction={callToAction} />
        </div>
      </div>
    </section>
  )
}

export default BrandIntro
