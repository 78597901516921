import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

import { XMarkIcon } from '@heroicons/react/24/outline'

interface IModal {
  show: boolean
  onClose: () => void
  children: React.ReactNode
}

const Modal: React.FC<IModal> = ({ children, onClose, show }) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [show])

  const handleCloseClick = (e) => {
    e.preventDefault()
    onClose()
  }

  const modalContent = () => {
    if (!show) {
      return null
    }
    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="dialog-background flex justify-center items-center"
          onClick={handleCloseClick}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className="p-4 bg-white my-8" onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
          <span className="absolute top-0 right-0 w-6 h-6 m-1 md:h-8 md:w-8 md:m-2 bg-white rounded-full">
            <XMarkIcon />
          </span>
        </div>
      </>
    )
  }

  if (show) {
    return createPortal(modalContent(), document.getElementById('modal-root'))
  }
  return null
}

export default Modal
