import React, { useEffect, useRef, useState } from 'react'

import { IDatoCMSSelfHostedVideo } from '@lib/datocms/types/misc'
import {
  trackVideoPlayerEventStart,
  trackVideoPlayerEventEnd
} from '@lib/tracking/trackVideoPlayerEvent'
import { useIntersectionObserverForVideoPause } from 'hooks/useIntersectionObserver'
import { getPosterUrl } from '@modules/common/utils'
import PlayButton from './PlayButton'

export interface IVideoPlayer {
  video: IDatoCMSSelfHostedVideo
  isInline?: boolean
}

const VideoElement: React.FC<IVideoPlayer> = ({ video, isInline = false }) => {
  const playerRef = useRef<HTMLVideoElement>(null)
  const posterUrl = getPosterUrl(video)
  const [isPlaying, setIsPlaying] = useState(false)

  const { ref } = useIntersectionObserverForVideoPause({
    action: () => playerRef.current.pause(),
    threshold: 0.2
  })

  const onHandleClick = () => {
    if (!isPlaying) {
      setIsPlaying(true)
      playerRef.current.play()
    }
  }

  const handleVideoStart = () => {
    if (!isPlaying) trackVideoPlayerEventStart(video)
  }
  const handleVideoEnd = () => {
    if (!isPlaying) trackVideoPlayerEventEnd(video)
  }

  useEffect(() => {
    playerRef.current.addEventListener('play', handleVideoStart)
    playerRef.current.addEventListener('ended', handleVideoEnd)

    return () => {
      if (playerRef.current) {
        playerRef.current.removeEventListener('play', handleVideoStart)
        playerRef.current.removeEventListener('ended', handleVideoEnd)
      }
    }
  }, [])

  const maxVideoWidth = 684 * 2

  return (
    <div ref={ref}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={playerRef}
        poster={`${posterUrl}?h=${
          (maxVideoWidth * video.height) / video.width
        }&w=${maxVideoWidth}&fit=crop&auto=format`}
        onClick={(e) => {
          if (!isInline) e.stopPropagation()
        }}
        controls
        autoPlay={!isInline}
        className="m-auto max-h-[90vh]">
        <source src={video.video.mp4Url} type="video/mp4" />
      </video>
      {isInline && !isPlaying && (
        <button
          type="button"
          className="absolute top-0 left-0 block group w-full h-full"
          onClick={onHandleClick}>
          <PlayButton />
        </button>
      )}
    </div>
  )
}

export default VideoElement
