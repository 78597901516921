import React from 'react'
import Image from 'next/image'
import { getDatoIndexKey } from '@lib/productService/utils'
import Product from '@components/content/contentSection/Products/Product'
import { IProductSnippetsBySlug } from '@modules/products/types'
import { IDatoCMSProductRecommendation } from '@modules/category/types'

import quoteIcon from '@assets//images/quote_icon.svg'

interface IProductWithRecommendationText {
  singleProduct: IDatoCMSProductRecommendation
  productSnippetsBySlug: IProductSnippetsBySlug
}

const ProductWithRecommendationText: React.FC<IProductWithRecommendationText> = ({
  singleProduct,
  productSnippetsBySlug
}) => {
  const product = productSnippetsBySlug[getDatoIndexKey(singleProduct.product)]

  if (!product) return null
  return (
    <div className="md:w-1/4 md:px-3">
      <div className="text-left">
        <Product product={product} imageSizes="(min-width: 767px) 25vw, 100vw" />
      </div>
      <div className="flex">
        <div className="w-full">
          <div className="mb-8 text-left">
            <blockquote className="block text-left md:text-base">
              <div className="pr-2 inline-block">
                <Image
                  alt="yoself Icon"
                  width="21"
                  height="15"
                  className="object-contain"
                  src={quoteIcon}
                />
              </div>
              <span>{singleProduct.text}</span>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductWithRecommendationText
