import React from 'react'
import Image from 'next/image'
import Carousel from '@components/Carousel'
import classNames from 'classnames'
import { getImageFormat, getNumberOfDesktopSlides } from '@modules/common/utils'
import { YoImageOrVideo } from '@modules/common/types'

export interface IImageGallery {
  headline: string
  images: YoImageOrVideo[]
  id: string
}

const ImageGallery: React.FC<IImageGallery> = ({ images, headline, id }) => {
  const format = getImageFormat(images[0])

  const carouselImageItems = images.map((image) => (
    <div
      key={image.alt}
      className={classNames('w-full relative', {
        'aspect-w-2 aspect-h-3 md:aspect-w-4 md:aspect-h-7': format === 'portraitInstagram',
        'aspect-square': format === 'square',
        'aspect-w-7 aspect-h-4': format === 'landscape'
      })}>
      <Image
        src={image.responsiveImage.src}
        fill
        style={{ objectPosition: image.focalPoint }}
        alt={image.alt}
        className="object-cover"
        sizes="(min-width: 767px) 50vw, 100vw"
      />
    </div>
  ))
  return (
    <section id={id} className="bg-white section-content-width section-spacing">
      <h2 className="overline-section mb-4">{headline}</h2>
      <div className="my-8 md:my-10">
        <Carousel
          id={id}
          carouselItems={carouselImageItems}
          slidesToShowMobile={1}
          slidesToShowDesktop={getNumberOfDesktopSlides(format)}
          width="trimmed"
        />
      </div>
    </section>
  )
}

export default ImageGallery
