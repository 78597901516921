import React from 'react'
import Carousel from '@components/Carousel'
import { IProductSnippetsBySlug } from '@modules/products/types'
import ExpertInExpertRecommendation from '@components/content/category/ContentSections/ExpertRecommendation/ExpertInExpertRecommendation'
import ProductWithRecommendationText from 'components/content/category/ContentSections/ExpertRecommendation/ProductWithRecommendationText'
import { ExpertRecommendationData } from '@modules/contentSections/types'
import { useIntersectionObserverForTracking } from 'hooks/useIntersectionObserver'
import trackViewItemListEvent from '@lib/tracking/trackViewItemListEvent'
import { getDatoIndexKey } from '@lib/productService/utils'

type ExpertRecommendationProps = {
  expertRecommendationData: ExpertRecommendationData
  productSnippetsBySlug: IProductSnippetsBySlug
}

const ExpertRecommendation = ({
  expertRecommendationData,
  productSnippetsBySlug
}: ExpertRecommendationProps) => {
  const { id, headline, products, image, text } = expertRecommendationData
  const carouselRecommendationProducts = products.map((singleProduct) => (
    <ProductWithRecommendationText
      key={singleProduct.id}
      singleProduct={singleProduct}
      productSnippetsBySlug={productSnippetsBySlug}
    />
  ))
  const productsForTracking = products.map(
    (product) => productSnippetsBySlug[getDatoIndexKey(product.product)]
  )

  const { ref } = useIntersectionObserverForTracking({
    action: () => trackViewItemListEvent(id, headline, productsForTracking),
    threshold: 0.2
  })

  return (
    <section id={id} className="bg-white section-spacing section-content-width" ref={ref}>
      <h2 className="overline-section mb-8">{headline}</h2>
      {/* Mobile Version */}
      <div className="md:hidden">
        <ExpertInExpertRecommendation image={image} text={text} />
        <Carousel
          id={id}
          carouselItems={carouselRecommendationProducts}
          slidesToShowMobile={1}
          slidesToShowDesktop={4}
          width="trimmed"
        />
      </div>
      {/* Desktop Version */}
      <div className="-mx-4 hidden md:block">
        <div className="flex flex-wrap md:flex-row md:px-1">
          <ExpertInExpertRecommendation {...expertRecommendationData} />
          {carouselRecommendationProducts}
        </div>
      </div>
    </section>
  )
}

export default ExpertRecommendation
