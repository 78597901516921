import VideoElement from '@components/VideoPlayer/VideoElement'
import { IOneImage } from '@modules/contentSections/types'
import Image from 'next/image'
import React from 'react'

const OneImage: React.FC<IOneImage> = ({ image, id }) => {
  const hasVideo = image?.video
  return (
    <section className="relative section-spacing section-content-width px-0">
      {hasVideo && (
        <div className="">
          <VideoElement video={image} isInline />
        </div>
      )}
      {!hasVideo && (
        <div id={id} className="relative">
          <Image
            alt={image.alt}
            src={image.responsiveImage.src}
            width={image.responsiveImage.width}
            height={image.responsiveImage.height}
            sizes="100vw"
          />
        </div>
      )}
    </section>
  )
}

export default OneImage
