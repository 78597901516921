import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Link from 'next/link'
import classNames from 'classnames'

import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import { getLinkFromCallToAction } from '@modules/common/utils'
import { ICallToAction } from '@modules/common/types'

interface ILinkOrAnchor {
  callToAction?: ICallToAction
  transparentWhiteHover?: boolean // 💡used only in stage sections
  regularBlack?: boolean
  whiteBlackHover?: boolean // 💡 homepage
  blank?: boolean
}

const LinkOrAnchorLink: React.FC<ILinkOrAnchor> = ({
  callToAction,
  transparentWhiteHover,
  regularBlack,
  whiteBlackHover,
  blank
}) => {
  if (!callToAction) return null
  if (!callToAction.buttonText) return null

  return getLinkFromCallToAction(callToAction) ? (
    <Link legacyBehavior href={getLinkFromCallToAction(callToAction)}>
      <a
        className={classNames(
          'btn',
          { 'btn--black': regularBlack },
          { 'btn--transparent': transparentWhiteHover },
          { 'btn--white': whiteBlackHover },
          { 'btn--blank': blank }
        )}>
        <ArrowToRightIcon />
        <span>{callToAction.buttonText}</span>
      </a>
    </Link>
  ) : (
    <AnchorLink
      href={`#${callToAction.target}`}
      offset={65}
      className={classNames(
        'btn',
        { 'btn--black': regularBlack },
        { 'btn--transparent': transparentWhiteHover },
        { 'btn--white': whiteBlackHover },
        { 'btn--blank': blank }
      )}>
      <ArrowToRightIcon />
      <span>{callToAction.buttonText}</span>
    </AnchorLink>
  )
}

export default LinkOrAnchorLink
