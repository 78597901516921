import { striptags } from 'striptags'
import { decode } from 'html-entities'

import { IShopifyProduct, IShopifyProductSnippet } from '@lib/shopify/interfaces'
import {
  IDatoCMSMinimalProduct,
  IDatoCMSProduct,
  IDatoCMSProductSnippet
} from '@lib/datocms/types/product'

export const findShopifyProductByHandle = (
  datoProduct: IDatoCMSProduct,
  shopifyProducts: IShopifyProduct[]
): IShopifyProduct | null =>
  shopifyProducts.find((shopifyProduct) => shopifyProduct.handle === datoProduct.shopifyProductId)

export const findShopifyProductSnippetByHandle = (
  datoProductSnippet: IDatoCMSProductSnippet,
  shopifyProductSnippets: IShopifyProductSnippet[]
): IShopifyProductSnippet | null =>
  shopifyProductSnippets.find(
    (shopifyProductSnippet) => shopifyProductSnippet.handle === datoProductSnippet.shopifyProductId
  )

export const getDatoIndexKey = (
  datoProduct: IDatoCMSProduct | IDatoCMSProductSnippet | IDatoCMSMinimalProduct
): string => datoProduct.shopifyProductId

export const makeProductDescription = (
  product: Pick<IDatoCMSProduct, 'shortDescription' | 'longDescription' | 'benefits'>
): string => {
  const { shortDescription, longDescription = [], benefits = [] } = product
  const segments = [shortDescription]
  const benefitString = benefits.map(({ text }) => text).join(' ')
  if (benefitString) segments.push(benefitString)

  const longDescString = longDescription.reduce((str, longDesc) => {
    const attrs = longDesc.attributesList.flatMap((attr) => [attr.name, attr.content])
    return str + [longDesc.text, ...attrs].join(' ')
  }, '')

  if (longDescString) segments.push(longDescString)

  const rawDescription = segments.join(' ')

  return decode(striptags(rawDescription))
}
