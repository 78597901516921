import React from 'react'
import classNames from 'classnames'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'

interface IMiniNavigation {
  switchCarouselItem: (direction) => void
  isHiddenOnDesktop: boolean
}

const MiniNavigation: React.FC<IMiniNavigation> = ({ switchCarouselItem, isHiddenOnDesktop }) => (
  <>
    <button
      type="button"
      aria-label="Zurück"
      className={classNames('slider-button z-1 left-0 -ml-3 top-1/2', {
        'md:hidden': isHiddenOnDesktop
      })}
      onClick={() => {
        switchCarouselItem('left')
      }}>
      <ArrowLeftIcon className="w-5 h-5" />
    </button>
    <button
      type="button"
      aria-label="Weiter"
      className={classNames('slider-button z-20 right-1 -mr-3 top-1/2', {
        'md:hidden': isHiddenOnDesktop
      })}
      onClick={() => {
        switchCarouselItem('right')
      }}>
      <ArrowRightIcon className="w-5 h-5" />
    </button>
  </>
)

export default MiniNavigation
