import React, { useEffect, useRef } from 'react'
import { useInView } from 'framer-motion'

type Props = {
  action: () => void
  threshold?: number
}

export const useIntersectionObserverForTracking = ({
  action,
  threshold = 0
}: Props): { ref: React.Ref<HTMLDivElement> } => {
  const ref = useRef(null)

  const inView = useInView(ref, { amount: threshold })

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        action()
      }, 500)
    }
  }, [inView])

  return { ref }
}
export const useIntersectionObserverForVideoPause = ({
  action,
  threshold = 0
}: Props): { ref: React.Ref<HTMLDivElement> } => {
  const ref = useRef(null)

  const inView = useInView(ref, { amount: threshold })

  useEffect(() => {
    if (!inView) {
      setTimeout(() => {
        action()
      }, 500)
    }
  }, [inView])

  return { ref }
}
