import React from 'react'
import { IDatoCMSSelfHostedVideo } from '@lib/datocms/types/misc'
import VideoElement from './VideoElement'

interface IVideoPlayer {
  video: IDatoCMSSelfHostedVideo
}

const VideoPlayer: React.FC<IVideoPlayer> = ({ video }) => (
  <div className="relative">
    <VideoElement video={video} isInline />
  </div>
)

export default VideoPlayer
