import { IProductSnippet } from '@modules/products/types'
import { shopifyToEuro } from '../utils'

export interface IPrices {
  fromPrice: string | null
  price: string
  regularPrice: string | null
}

const formatPrice = (price: string): string => shopifyToEuro(parseFloat(price))

export function getFromPrice(product: IProductSnippet): string | null {
  if (
    parseFloat(product.priceRange.minVariantPrice.amount) ===
    parseFloat(product.priceRange.maxVariantPrice.amount)
  ) {
    return null
  }
  return `ab ${formatPrice(product.priceRange.minVariantPrice.amount)}`
}

function getPrice(product: IProductSnippet): string | null {
  return formatPrice(product.priceRange.minVariantPrice.amount)
}

export function getRegularPrice(product: IProductSnippet): string | null {
  return parseFloat(product.compareAtPriceRange.minVariantPrice.amount) >
    parseFloat(product.priceRange.minVariantPrice.amount)
    ? formatPrice(product.compareAtPriceRange.minVariantPrice.amount)
    : null
}

const getPrices = ({ product }): IPrices => {
  const fromPrice = getFromPrice(product)
  const price = getPrice(product)
  const regularPrice = getRegularPrice(product)

  return {
    fromPrice,
    price,
    regularPrice
  }
}

export const bundleDiscount = (price: string, regularPrice: string): number =>
  Math.floor(100 - (parseFloat(price) / parseFloat(regularPrice)) * 100)

export default getPrices
