import React, { useContext } from 'react'

import { getDatoIndexKey } from '@lib/productService/utils'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import { useIntersectionObserverForTracking } from 'hooks/useIntersectionObserver'
import UiContext from '@context/UiContext'
import { IProductSnippetsBySlug } from '@modules/products/types'
import trackViewItemListEvent from '@lib/tracking/trackViewItemListEvent'
import { defaultListSize } from '@utils/constants'
import { IDatoCMSProductListRecord } from '@lib/datocms/types'
import Product from './Products/Product'

type ProductsProps = {
  productListRecord: IDatoCMSProductListRecord
  productSnippetsBySlug: IProductSnippetsBySlug
  id: string
}

const Products = ({ productListRecord, productSnippetsBySlug, id }: ProductsProps) => {
  const uiContext = useContext(UiContext)
  const listSize = Number(productListRecord.displayedListLength) || defaultListSize
  const { productListsExpanded, setProductListsCurrentlyExpanded } = uiContext
  const isExpanded = productListsExpanded.includes(productListRecord.id)

  const handleShowMoreProductsClick = () => {
    setProductListsCurrentlyExpanded(productListRecord.id)
  }

  const displayedProducts = productListRecord.products
    .filter((_, index) => index < listSize || isExpanded)
    .map((datoCMSProduct) => productSnippetsBySlug[getDatoIndexKey(datoCMSProduct)] || null)

  const { ref } = useIntersectionObserverForTracking({
    action: () => trackViewItemListEvent(id, productListRecord.headline, displayedProducts),
    threshold: 0.2
  })

  return (
    <section id={id} className="section-spacing section-content-width" ref={ref}>
      <h2 className="overline-section">{productListRecord.headline}</h2>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 w-full mt-8 md:mt-10">
        {displayedProducts.map((product) => {
          if (product) {
            return (
              <Product
                key={`${productListRecord.id}_${product.shopifyProductHandle}`}
                product={product}
                imageType={productListRecord.imageType}
                imageSizes="(min-width: 767px) 25vw, 50vw"
              />
            )
          }
          return null
        })}
      </div>

      {productListRecord.products.length > listSize && !isExpanded && (
        <div className="flex justify-center w-full mt-8 md:mt-16">
          <button className="btn btn--black" onClick={handleShowMoreProductsClick} type="button">
            <ArrowToRightIcon />
            <span>Mehr Produkte</span>
          </button>
        </div>
      )}
    </section>
  )
}

export default Products
