import React from 'react'

const PlayIcon: React.FC = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36">
    <polygon fill="currentColor" points="4.926 1.119 32.18 18.111 4.926 35.103 4.926 1.119" />
  </svg>
)

export default PlayIcon
