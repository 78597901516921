import React from 'react'

import { getDatoIndexKey } from '@lib/productService/utils'
import { IProductSnippet, IProductSnippetsBySlug } from '@modules/products/types'
import Image from 'next/image'
import getPrices from '@utils/price'
import Link from 'next/link'
import { Price } from '@components/Price'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import { IPersonalizedHeroProduct } from '@lib/datocms/types/personalizedContent'
import SleepcheckContext from '@context/SleepCheckContext'
import Spinner from '@components/icons/Spinner'
import Stars from '@components/Stars'
import { useIntersectionObserverForTracking } from 'hooks/useIntersectionObserver'
import trackViewItemListEvent from '@lib/tracking/trackViewItemListEvent'
import { YoImage, YoImageOrVideo } from '@modules/common/types'
import VideoModal from '@components/VideoModal'

interface HeroProductProps {
  heroProduct: IPersonalizedHeroProduct
  productSnippetsBySlug: IProductSnippetsBySlug
  positionIndicator?: string
}

const HeroProduct = ({
  heroProduct,
  productSnippetsBySlug,
  positionIndicator
}: HeroProductProps) => {
  const { isLoading } = React.useContext(SleepcheckContext)
  const product = productSnippetsBySlug[getDatoIndexKey(heroProduct.product)]
  const { price, regularPrice } = getPrices({ product })
  const showBundleBatch = product.isBundle

  const showDealBatch = regularPrice && regularPrice !== price

  const productImage = product.firstImage

  const heroImage = heroProduct?.heroImage || productImage

  if (!product) return null
  const { ref } = useIntersectionObserverForTracking({
    action: () =>
      trackViewItemListEvent(heroProduct.id, heroProduct.headline, [product as IProductSnippet]),
    threshold: 0.2
  })
  return (
    <div
      className="relative w-full h-full flex flex-col justify-start bg-gray-200 lg:bg-transparent group"
      ref={ref}>
      <div className="flex flex-col lg:flex-row ">
        <div className="w-full relative mb-4">
          <div className="w-full aspect-w-4 aspect-h-3 overflow-hidden lg:-z-1">
            {heroImage?.responsiveImage && (
              <Link href={`/products/${product.shopifyProductHandle}`}>
                <Image
                  src={heroImage.responsiveImage.src}
                  alt={heroImage.alt}
                  fill
                  className="object-cover group-hover:scale-105 transition-all"
                  style={{ objectPosition: (heroImage as YoImage).focalPoint }}
                  sizes="(min-width: 1020px) 65vw, 100vw"
                />
              </Link>
            )}
            <div className="">
              {(heroImage as YoImageOrVideo)?.video && (
                <VideoModal video={heroImage as YoImageOrVideo} posterFormat="square" />
              )}
            </div>
          </div>

          {heroProduct.personalized && (
            <div className="absolute top-0 left-0 m-2 md:m-4 py-1 px-2 uppercase tracking-wider bg-black text-white text-xs font-bold z-1">
              Für dich
            </div>
          )}
          {showDealBatch && !heroProduct.personalized && (
            <div className="absolute top-0 left-0 m-2 md:m-4 py-1 px-2 uppercase tracking-wider bg-black text-white text-xs font-bold z-1">
              Deal
            </div>
          )}
          {showBundleBatch && !heroProduct.personalized && (
            <div className="absolute top-0 right-0 m-2 md:m-4 py-1 px-2 uppercase tracking-wider bg-black text-white text-xs font-bold z-1">
              Bundle
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center lg:w-[70%] lg:-ml-20 px-4 py-2 lg:p-0">
          <div className="bg-gray-200 justify-center self-center w-full lg:p-16">
            <h4 className="text-base font-bold mb-5 lg:mb-7">
              {positionIndicator && <span className="mr-2">{positionIndicator} </span>}
              <span>{heroProduct.headline}</span>
            </h4>
            <div
              className="text-base mb-2 mt-1 md:mb-4 text-content-section small-box-list"
              dangerouslySetInnerHTML={{ __html: heroProduct.text }}
            />
            <Link href={`/products/${product.shopifyProductHandle}`}>
              <div className="my-5 bg-gray-200">
                <span className="uppercase text-xs md:text-sm tracking-wide">
                  {product.brand.name}
                </span>
                <h4 className="font-bold text-base w-full break-words">{product.name}</h4>
                {product.reviews.count && (
                  <div className="my-1">
                    <Stars
                      score={product.reviews.average}
                      numberOfReviews={product.reviews.count}
                      size="small"
                    />
                  </div>
                )}
                <Price product={product} />
                <div className="block">
                  <div className="btn btn--black mt-4 lg:mt-6">
                    <ArrowToRightIcon />
                    <span>Zum Produkt</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {isLoading && (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-70 z-1 flex justify-center items-center">
            <Spinner size={80} />
          </div>
        )}
      </div>
    </div>
  )
}

export default HeroProduct
