import React from 'react'
import PlayIcon from '@components/icons/PlayIcon'

const PlayButton: React.FC = () => (
  <div className="absolute flex justify-center items-center transform pl-1.5 h-20 w-20 bg-black border-white border-opacity-80 border-4 rounded-full text-white transition-opacity duration-300 opacity-60 group-hover:opacity-90 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
    <PlayIcon />
  </div>
)

export default PlayButton
