import React from 'react'
import { getLinkFromCallToAction } from '@modules/common/utils'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Link from 'next/link'
import { ICallToActionSection } from '@modules/contentSections/types'

const CallToActionSection = ({ text, link }: ICallToActionSection) => (
  <section className="bg-white section-content-width py-8 md:py-16">
    <div className="flex flex-col items-center justify-center">
      {getLinkFromCallToAction(link) ? (
        <Link legacyBehavior href={getLinkFromCallToAction(link)}>
          <a className="btn btn--black justify-center">
            <ArrowToRightIcon />
            <span>{link.buttonText}</span>
          </a>
        </Link>
      ) : (
        <AnchorLink href={`#${link.target}`} offset={65} className="btn btn--black justify-center">
          <ArrowToRightIcon />
          <span>{link.buttonText}</span>
        </AnchorLink>
      )}

      {text && <p className="text-content-section font-bold text-sm mt-4 text-center">{text}</p>}
    </div>
  </section>
)

export default CallToActionSection
