import React from 'react'
import classNames from 'classnames'

import { IProduct } from '@modules/products/types'
import getVariantPrices from '@utils/variantPrice'

type PriceProps = {
  product: IProduct
  variantSKU: string
  textSize?: 'sm' | 'lg'
}

const VariantPrice = ({ product, variantSKU, textSize = 'lg' }: PriceProps) => {
  const { price, regularPrice } = getVariantPrices({ product, variantSKU })
  return (
    <div>
      <span
        data-testid="price"
        className={classNames('font-bold md:font-normal', {
          'text-red-700': regularPrice,
          'mr-4 text-base md:text-4xl': textSize === 'lg',
          'mr-2 text-sm md:text-base lg:text-xl': textSize === 'sm'
        })}>
        {price}
      </span>
      {regularPrice && (
        <span
          data-testid="regular-price"
          className={classNames('mr-4 line-through text-gray-400', {
            'text-base md:text-2xl': textSize === 'lg',
            'text-sm md:text-base lg:text-xl': textSize === 'sm'
          })}>
          {regularPrice}
        </span>
      )}
    </div>
  )
}

export default VariantPrice
