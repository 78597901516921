import React, { useState } from 'react'
import classNames from 'classnames'
import Image from 'next/image'

import { IDatoCMSSelfHostedVideo } from '@lib/datocms/types/misc'
import PlayButton from '@components/VideoPlayer/PlayButton'
import VideoElement from '@components/VideoPlayer/VideoElement'
import Modal from '@components/Modal'
import { getPosterUrl } from '@modules/common/utils'

interface IVideoPlayer {
  video: IDatoCMSSelfHostedVideo
  posterFormat?: '4:3' | '3:4' | 'square'
}

const VideoModal: React.FC<IVideoPlayer> = ({ video, posterFormat = '3:4' }) => {
  const posterUrl = getPosterUrl(video)
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <button
        type="button"
        className={classNames('relative block w-full', {
          'aspect-w-3 aspect-h-4': posterFormat === '3:4',
          'aspect-w-4 aspect-h-3': posterFormat === '4:3',
          'aspect-square': posterFormat === 'square'
        })}
        onClick={() => {
          setShowModal(true)
        }}>
        <Image
          alt={video.title}
          src={posterUrl}
          fill
          className="object-cover"
          sizes="(min-width: 767px) 25vw, 80vw"
        />
        <PlayButton />
      </button>
      <Modal onClose={() => setShowModal(false)} show={showModal}>
        <VideoElement video={video} />
      </Modal>
    </>
  )
}

export default VideoModal
